import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-not-found-v2',
	templateUrl: './not-found-v2.component.html',
	styleUrls: ['./not-found-v2.component.scss']
})
export class NotFoundV2Component {
	public subscribe: Subscription;
}
