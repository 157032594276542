import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dayTable, monthTable, OpeningHours, OpeningHoursShowOption, OpeningHoursType } from 'src/app/core/models/opening-hours.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';

@Component({
	selector: 'app-footer-opening-hours',
	templateUrl: './opening-hours.component.html',
	styleUrls: ['./opening-hours.component.scss']
})
export class FooterOpeningHoursComponent implements OnChanges {
  public hours: {[key: string]: any};
  public lines: string[] = [];

  @Input() displayTitle: boolean = true;
  @Input() qrcode: Qrcode;
  @Input() version: string = 'v2';

  constructor(
		public translateService: TranslateService,
    protected datePipe: DatePipe

	) {}

  ngOnChanges(changes: SimpleChanges) {
    this.lines = this.qrcode.getHours();
    if(changes.qrcode) {
      this.hours = this.qrcode.openingHours.filter(v => {
          if(v.type === OpeningHoursType.regular) return true;
        
          if(v.showOption === OpeningHoursShowOption.always) return true;
          if(v.showOption === OpeningHoursShowOption.never) return false;

          const d = new Date(v.showAt);

          return d.getTime() <= (new Date()).getTime();
              
      }).sort((a, b) => {
        let minA: any = dayTable.find(v => a.days & v.value);
        let minB: any = dayTable.find(v => b.days & v.value);

        minA = minA ? minA.value : 0;
        minB = minB ? minB.value : 0;

        return minA > minB ? 1 : (minB > minA ? -1 : 0)
      })
      .reduce((acc, curr) => {
        if(curr.type === OpeningHoursType.regular || curr.type === OpeningHoursType.occasional) acc[curr.type] = [...acc[curr.type], curr]
        else {
          const keys = Object.keys(acc[curr.type]);
          const format = 'dd-MM-yyyy';
          const to_check = this.datePipe.transform(curr.beginAt, format) + ' - ' +  this.datePipe.transform(curr.endAt, format)
          
          if(keys.includes(to_check)) acc[curr.type][to_check] = [...acc[curr.type][to_check], curr]
          else acc[curr.type][to_check] = [curr]
        }
        return acc;

      }, {
        regular: [],
        event: {},
        occasional: [],
      });
    }
  }

  getDay(oh: OpeningHours) {
    const f = dayTable.find(v => oh.days & v.value)

    if(f) return f.name;
  }

  getKeys(v) {
    return Object.keys(v)
  }

  get lenght() {
    return this.qrcode.openingHours.length > 0
  }

  getFormattedDate(date: Date, pref: string = 'v2.components.footer.opening_hours') {
   const day = date.getDay() === 0 ? 7 : date.getDay();
   const month = date.getMonth();

   const dayStr = dayTable.find(({index}) => index === day).name;
   const monthStr = monthTable.find(({value}) => value === month + 1).name;
   return this.translateService.instant(`${pref}.${dayStr}`) + ' ' + (date.getDate() > 9 ? '' : '0') + date.getDate() + ' ' + this.translateService.instant(`${pref}.${monthStr}`) + ' ' + date.getFullYear();
  }

  getEventDate(begin: Date, end: Date, pref: string = 'v2.components.footer.opening_hours') {
    return this.translateService.instant(`${pref}.days.d2`, {
      t1: this.getFormattedDate(begin),
      t2: this.getFormattedDate(end)
    });
  }

  ftext(t: string) {
    return t.charAt(0).toUpperCase() + t.slice(1)
  } 

}