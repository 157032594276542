export const environment = {
  production: true,
  baseUrl: 'https://api.access-evolution.com',
  appUrl: 'https://menu.access-evolution.com',
  googleAppId: '468571638057-dubnhsedf94jvke55vkjpn7p528os8b1.apps.googleusercontent.com',
  fbAppId: '867857651069005',
  googleApiKey: 'AIzaSyBX7j7S53_uhVNlKa1CpefU4iEbj9-Q0Lg',
  googleGeocodeApiKey: 'AIzaSyCO5a8yq-NnF9ikvPAhdQES2z8smbAdQfQ',
  googleApiUrl: 'https://maps.googleapis.com/maps/api/js?key=:api_key&libraries=drawing,places',
  geoCodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json?address=:address&key=:api_key',
  geoCodeUrlReverse: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=:latLng&key=:api_key',
  stripePublicKey: 'pk_live_51IYRZfGNNlTCux0Z9hCs802tDbJro7Sx4ouKySluGKosNkSdKguQ7MK028VXj59rdvggcHWo03PsOBcYVfjfLt1V00Y1g6Pid7',
  sendgrid: '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'
};