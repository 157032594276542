import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AddHeaderInterceptor } from './interceptors/add-header.interceptor';
import { AuthService } from './services/auth.service';
import { NavigationService } from './services/navigation.service';
import { GoogleMapService } from './services/google-map.service';
import { OverlayService } from './services/overlay.service';
import { ProcessService } from './services/process.service';
import { AllergenService } from './services/api/allergen.service';
import { ClientService } from './services/api/client.service';
import { FamillyService } from './services/api/familly.service';
import { CustomerService } from './services/api/customer.service';
import { QrCodeService } from './services/api/qrcode.service';
import { PaymentService } from './services/api/payment.service';
import { ProductService } from './services/api/product.service';
import { ClickCollectService } from './services/api/click-collect.service';
import { ShopCartService } from './services/shop-cart.service';
import { OrderService } from './services/api/order.service';
import { AppStateService } from './services/app-state.service';
import { DateService } from './services/date.service';
import { StripeService } from './services/stripe.service';
import { ModalService } from './services/modal.service';
import { DecimalPipe } from '@angular/common';
import { TableRuleService } from './services/api/table-rule.service';
import { VersionService } from './services/api/version.service';
import { LoaderService } from './services/loader.service';
import { RouterService } from './services/router.service';
import { AddLoaderInterceptor } from './interceptors/add-loader.interceptor';

@NgModule({
	providers: [
		AllergenService,
		ClientService,
		FamillyService,
		CustomerService,
		QrCodeService,
		PaymentService,
		ProductService,
		ClickCollectService,
		OrderService,
		AuthService,
		NavigationService,
		VersionService,
		GoogleMapService,
		OverlayService,
		ModalService,
		ShopCartService,
		ProcessService,
		AppStateService,
		DateService,
		DecimalPipe,
		StripeService,
		TableRuleService,
		LoaderService,
		RouterService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AddHeaderInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AddLoaderInterceptor,
			multi: true
		}
	]
})
export class CoreModule {}
