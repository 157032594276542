import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SwiperModule } from 'swiper/angular';
import SwiperCore, {
	Autoplay,
	Pagination
} from 'swiper/core';
import { FooterOpeningHoursComponent } from "./components/footer/opening-hours/opening-hours.component";
import { StripHTMLPipe } from "./pipes/strip-html.pipe";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotFoundV1Component } from "../feature/v1/components/not-found-v1/not-found-v1.component";
import { NotFoundV2Component } from "./components/not-found-v2/not-found-v2.component";

SwiperCore.use([Pagination, Autoplay]);

const declarations = [
	StripHTMLPipe,
	FooterOpeningHoursComponent,
	NotFoundV1Component,
	NotFoundV2Component
];

const imports = [
	CommonModule, 
	RouterModule, 
	HttpClientModule,
	HttpClientJsonpModule,
	TranslateModule,
	SwiperModule,
	MatProgressBarModule,
	MatProgressSpinnerModule
]

@NgModule({
	declarations,
	imports,
	providers: [
		DatePipe
	],
	exports : [
		...declarations,
		...imports
	]
})
export class SharedModule {}
